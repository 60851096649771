export const businessTypeOptions = [
  { value: "airline", label: "Airline" },
  { value: "tour operator", label: "Tour Operator" },
  { value: "online travel agency", label: "Online Travel Agency" },
  {
    value: "destination management company",
    label: "Destination Management Company",
  },
  { value: "travel agent", label: "Travel Agent" },
  { value: "bedbank", label: "Bedbank" },
  { value: "wholesaler", label: "Wholesaler" },
  { value: "consolidator", label: "Consolidator" },
  { value: "tech platform", label: "Tech Platform" },
  { value: "other", label: "Other" },
];

export const productOptions = [
  { value: "hotels", label: "Hotels" },
  { value: "transfers", label: "Transfers" },
  { value: "activities", label: "Activities" },
  { value: "flights", label: "Flights" },
  { value: "cars", label: "Cars" },
  { value: "ground packages", label: "Ground packages" },
  { value: "dynamic packaging", label: "Dynamic packaging" },
  { value: "other", label: "Other" },
];
