import React, { FC } from "react";
import Select, { StylesConfig } from "react-select";
import { DropdownOption } from "../declarations";
import { Controller, Control, FieldValues } from "react-hook-form";

export interface ValidatedMultiSelectProps {
  label?: string;
  placeholder: string;
  name: string;
  options: DropdownOption[];
  errorMessage?: string;
  control?: Control<FieldValues>;
}

const customStyles: StylesConfig<DropdownOption, boolean> = {
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    minHeight: "36px",
    flex: "1 1 0%",
    padding: "0 0 0 6px",
  }),
  indicatorSeparator: (baseStyles) => ({
    ...baseStyles,
    alignSelf: "stretch",
    backgroundColor: "rgba(204, 204, 204, 0.5)",
    marginBottom: 8,
    marginTop: 8,
    width: 1,
  }),
  dropdownIndicator: (baseStyles, { selectProps: { menuIsOpen } }) => ({
    ...baseStyles,
    color: "#999",
    transition: "all .2s ease",
    transform: menuIsOpen && "rotate(180deg)",
    cursor: "pointer",
    padding: 4,
    ":hover": {
      color: "#999",
    },
  }),
  clearIndicator: (baseStyles) => ({
    ...baseStyles,
    padding: 4,
    cursor: "pointer",
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    fontFamily: "inherit",
    border: "2px solid rgba(204, 204, 204, 0.5)",
    borderRadius: 0,
    width: "100%",
    boxShadow: "none",
    minHeight: 30,
    ":hover": {
      borderColor: "rgba(204, 204, 204, 0.5)",
    },
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    borderRadius: 0,
    marginTop: 2,
    boxShadow: "0 0 0 1px rgb(68 68 68 / 11%)",
    zIndex: 9,
  }),
  option: (baseStyles, { isFocused, isSelected }) => ({
    ...baseStyles,
    cursor: "pointer",
    color: "#4A4A4A",
    fontWeight: isSelected ? "bold" : "normal",
    lineHeight: "40px",
    padding: "0 29px 0 18px",
    backgroundColor: isFocused ? "#f6f6f6" : "#FFF",
  }),
  multiValueLabel: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: "#FFF",
    color: "#4a4a4a",
    padding: 0,
    paddingLeft: 0,
  }),
  multiValueRemove: (baseStyles) => ({
    ...baseStyles,
    background: "#FFF",
    color: "#999",
    cursor: "pointer",
    paddingLeft: 0,
    ":hover": {
      background: "#FFF",
      color: "#4a4a4a",
    },
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    margin: 0,
    padding: 0,
  }),
};

export const ValidatedMultiSelect: FC<ValidatedMultiSelectProps> = ({
  label,
  placeholder,
  name,
  options,
  errorMessage,
  control,
}) => (
  <>
    {!!label && (
      <label htmlFor={name} className="required">
        {label}
      </label>
    )}

    <Controller
      control={control}
      name={name}
      rules={{ required: true }}
      render={({ field }) => (
        <Select
          {...field}
          name={name}
          placeholder={placeholder}
          closeMenuOnSelect={false}
          isMulti
          options={options}
          styles={customStyles}
        />
      )}
    />
    {!!errorMessage && <p className="error">{errorMessage}</p>}
  </>
);
