import React, { FC } from "react";
import { graphql, PageProps } from "gatsby";
import { MainLayout } from "@/layouts/MainLayout";
import Helmet from "react-helmet";
import { SEO } from "@/components/basic/SEO";
import { emailRegExp } from "@/utils/validation";
import { Dropdown } from "@/components/basic/Dropdown";
import { ValidatedMultiSelect } from "@/components/ValidatedMultiSelect";
import { Input } from "@/components/basic/Input";
import { ValidatedInput } from "@/components/basic/ValidatedInput";
import { PreviousPageInput } from "@/components/basic/PreviousPageInput";
import { Textarea } from "@/components/basic/Textarea";
import { MailingListCheckbox } from "@/components/MailingListCheckbox";
import { SuccessPageContent } from "@/components/SuccessPageContent";
import { FailurePageContent } from "@/components/FailurePageContent";
import { FormSubmitButton } from "@/components/basic/FormSubmitButton";
import { SiteFooterNewsletterButton } from "@/components/basic/SiteFooterNewsletterButton";
import { SiteFooterWrapper } from "@/components/basic/SiteFooterWrapper";
import { PrivacyPolicyInfo } from "@/components/PrivacyPolicyInfo";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormHandler } from "@/hooks/useFormHandler";
import { businessTypeOptions, productOptions } from "@/utils/requestDemoData";
import {
  requiredFieldMessage as required,
  invalidFormatMessage as invalid,
} from "@/utils/validationMessages";

const DemoForm: FC<PageProps<GatsbyTypes.Query>> = ({ data }) => {
  const {
    metaTitle,
    metaDescription,
    metaKeywords,
  } = data.contentfulRequestDemoPage;
  const {
    state,
    errors,
    isValid,
    isRecaptcha,
    register,
    handleCaptchaChange,
    handleFormSubmit,
    handleMailingListChange,
    control,
  } = useFormHandler(process.env.FORMCARRY_REQUEST_DEMO_FORM_ID);

  return (
    <MainLayout>
      <Helmet>
        <body className="ltr demo-form" id="intro" />
      </Helmet>
      <SEO
        title={metaTitle}
        description={metaDescription.metaDescription}
        keywords={metaKeywords}
      />
      {state.submitted && (
        <SuccessPageContent
          heading="Thank you!"
          text="Your request has been submitted. We’ll get back to you soon to schedule an online presentation."
        />
      )}
      {state.error && (
        <FailurePageContent goBackUrl="/demo-form" goBackText="return" />
      )}
      {!state.submitted && !state.error && (
        <section className="overlay overlay-static">
          <div className="l-wrapper l-with-dbl-vertical-gutters-mobile l-dbl-push-top">
            <div className="l-10-cols-tablet l-8-cols-desktop l-island default-form color-bg-negative l-with-dbl-gutters-mobile">
              <div className="l-full">
                <h2 className="color-primary">Request a Demo</h2>
                <p className="text-bold l-push-bottom">
                  Looking for next-gen travel software?
                </p>
                <p className="l-push-bottom">
                  Would you like to see ANIXE’s Resfinity platform in action?
                </p>
                <p className="l-push-bottom">
                  Please complete the form to help us understand your
                  requirement, and accurately cater to your needs. We will
                  contact you ASAP to schedule an online presentation.
                </p>
                <p className="l-push-bottom">
                  <strong className="color-error">*</strong>
                  required fields
                </p>
              </div>
              <div className="l-push-bottom l-bleed">
                <form onSubmit={handleFormSubmit}>
                  <div className="l-push-bottom clearfix">
                    <div className="l-half-tablet">
                      <ValidatedInput
                        name="name"
                        label="Name"
                        register={register}
                        errorMessage={errors.name && required}
                      />
                    </div>
                    <div className="l-half-tablet">
                      <ValidatedInput
                        name="email"
                        type="email"
                        label="Email address"
                        register={register}
                        pattern={emailRegExp}
                        errorMessage={
                          errors.email &&
                          (errors.email.type === "pattern" ? invalid : required)
                        }
                      />
                    </div>
                  </div>
                  <div className="l-push-bottom clearfix">
                    <div className="l-half-tablet">
                      <ValidatedInput
                        name="companyWebsite"
                        label="Company Website"
                        register={register}
                        errorMessage={errors.companyWebsite && required}
                      />
                    </div>
                    <div className="l-half-tablet">
                      <ValidatedInput
                        name="topDestinations"
                        label="Top Destinations"
                        register={register}
                        errorMessage={errors.topDestinations && required}
                      />
                    </div>
                  </div>
                  <div className="l-push-bottom clearfix">
                    <div className="l-half-tablet">
                      <Dropdown
                        label="Type of Business"
                        name="businessType"
                        placeholder="Select options"
                        options={businessTypeOptions}
                      />
                    </div>
                    <div className="l-half-tablet">
                      <ValidatedMultiSelect
                        label="Product"
                        name="product"
                        placeholder="Select options"
                        options={productOptions}
                        errorMessage={errors.product && required}
                        control={control}
                      />
                    </div>
                  </div>
                  <div className="l-push-bottom clearfix">
                    <div className="l-half-tablet">
                      <Input
                        name="thirdPartyIntegrations"
                        label="Third Party Integrations"
                      />
                    </div>
                    <div className="l-half-tablet">
                      <Input
                        name="desiredNewIntegrations"
                        label="Desired New Integrations"
                      />
                    </div>
                  </div>
                  <div className="l-push-bottom clearfix">
                    <div className="l-4-cols-tablet">
                      <Input name="salesChannels" label="Sales Channels" />
                    </div>
                    <div className="l-4-cols-tablet">
                      <Input
                        name="ownContracts"
                        label="Number of Own Contracts"
                      />
                    </div>
                    <div className="l-4-cols-tablet">
                      <Input name="budget" label="Budget" />
                    </div>
                  </div>
                  <div className="l-full-mobile l-push-bottom">
                    <Textarea label="Current Challenges?" name="challenges" />
                  </div>
                  <PrivacyPolicyInfo />
                  <div className="l-full-mobile l-dbl-push-bottom text-12">
                    <MailingListCheckbox
                      handleChange={handleMailingListChange}
                    />
                  </div>
                  <PreviousPageInput />
                  <div className="align-center clearfix">
                    <div className="l-half-tablet">
                      <ReCAPTCHA
                        sitekey={process.env.FORMCARRY_SITEKEY}
                        onChange={handleCaptchaChange}
                      />
                    </div>
                    <FormSubmitButton
                      text="agree & send"
                      disabled={!isRecaptcha || !isValid}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
      <SiteFooterWrapper>
        <SiteFooterNewsletterButton />
      </SiteFooterWrapper>
    </MainLayout>
  );
};

export default DemoForm;
export const query = graphql`
  {
    contentfulRequestDemoPage {
      metaTitle
      metaDescription {
        metaDescription
      }
      metaKeywords
    }
  }
`;
